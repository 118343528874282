/*======================================
	Start Header CSS
========================================*/
.header {
    width: 100%;
    background: transparent;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;

    @media #{$md} {
        padding: 18px 0 !important;

        .mobile-menu-btn .toggler-icon {
            background-color: #fff;
        }

        .button {
            margin: 0 !important;
        }

        .navbar-collapse {
            position: absolute;
            top: 67px !important;
            left: 0;
            width: 100%;
            background-color: #fff;
            z-index: 9;
            box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
            padding: 10px 20px;
            max-height: 350px;
            overflow-y: scroll;
            border-top: 1px solid #eee;
            border-radius: 6px;
        }

        .navbar .navbar-nav .nav-item a {
            &:hover {
                color: $theme-color !important;
            }

            &.active {
                color: $theme-color !important;
            }
        }

        .navbar-nav .nav-item {
            margin: 0;

            &:hover a {
                color: $theme-color;
            }

            a {
                padding: 12px 16px !important;

                &::before {
                    display: none;
                }
            }
        }

        .navbar-nav .nav-item .sub-menu {
            position: static;
            width: 100%;
            opacity: 1;
            visibility: visible;
            box-shadow: none;
            padding: 0;
            border: none;
            margin-left: 15px;
            margin-right: 15px;

            .nav-item a {
                padding: 12px 12px;

                &:hover {
                    color: $theme-color !important;
                }
            }
        }

        .navbar-nav .nav-item a {
            color: #051441;
            display: flex;
            justify-content: space-between;
            padding: 10px 0;

            &::after {
                opacity: 1;
                visibility: visible;
            }
        }

        .navbar-nav .nav-item .sub-menu li.active {
            background: $white !important;
            color: $theme-color !important;
        }

        .navbar-nav .nav-item .sub-menu {
            .nav-item {
                margin: 0 !important;

                a {
                    padding: 10px 12px !important;
                }
            }
        }

        .navbar-nav .nav-item .sub-menu li:hover {
            background: $white !important;
            color: $theme-color !important;
        }

        .navbar-nav .nav-item a {
            font-size: 14px;

            &:hover {
                color: $theme-color;
            }
        }
    }

    @media #{$xs} {
        padding: 18px 0 !important;

        .mobile-menu-btn .toggler-icon {
            background-color: #fff;
        }

        .navbar-collapse {
            position: absolute;
            top: 58px !important;
            left: 0;
            width: 100%;
            background-color: #fff;
            z-index: 9;
            box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
            padding: 10px 20px;
            max-height: 350px;
            overflow-y: scroll;
            border-top: 1px solid #eee;
            border-radius: 6px;
        }

        .navbar .navbar-nav .nav-item a {
            &:hover {
                color: $theme-color !important;
            }

            &.active {
                color: $theme-color !important;
            }
        }

        .navbar-nav .nav-item {
            margin: 0;

            &:hover a {
                color: $theme-color;
            }

            a {
                padding: 12px 16px !important;

                &::before {
                    display: none;
                }
            }
        }

        .navbar-nav .nav-item .sub-menu {
            position: static;
            width: 100%;
            opacity: 1;
            visibility: visible;
            box-shadow: none;
            padding: 0;
            border: none;
            margin-left: 15px;
            margin-right: 15px;

            .nav-item a {
                padding: 12px 12px;

                &:hover {
                    color: $theme-color !important;
                }
            }
        }

        .navbar-nav .nav-item a {
            color: #051441;
            display: flex;
            justify-content: space-between;
            padding: 10px 0;

            &::after {
                opacity: 1;
                visibility: visible;
            }
        }

        .navbar-nav .nav-item .sub-menu li.active {
            background: $white !important;
            color: $theme-color !important;
        }

        .navbar-nav .nav-item .sub-menu {
            .nav-item {
                margin: 0 !important;

                a {
                    padding: 10px 12px !important;
                }
            }
        }

        .navbar-nav .nav-item .sub-menu li:hover {
            background: $white !important;
            color: $theme-color !important;
        }

        .navbar-nav .nav-item a {
            font-size: 14px;

            &:hover {
                color: $theme-color;
            }
        }
    }

    .button {

        @media #{$xs} {
            display: none;
        }

        .btn {
            background-color: transparent;
            border: 2px solid #fff;
            color: $white;
            padding: 12px 30px;

            &:hover {
                background-color: $white;
                color: $theme-color;
            }

        }
    }

    .navbar-brand {
        img {
            width: 160px;

            @media #{$md} {
                width: 170px;
            }

            @media #{$xs} {
                width: 160px;
            }
        }
    }

    &.sticky {
        .navbar-brand {
            .white-logo {
                opacity: 0;
                visibility: hidden;
            }

            .dark-logo {
                opacity: 1;
                visibility: visible;
            }
        }

        .button {
            .btn {
                background-color: $theme-color;
                color: $white;
                border-color: transparent;

                &:hover {
                    background-color: $black;
                    color: $white;
                }
            }
        }
    }
}

.sticky {
    position: fixed;
    z-index: 99;
    background-color: #fff;
    box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease-out 0s;
    top: 0;
}

.navbar-expand-lg .navbar-nav {
    margin: 0;
    margin-left: auto !important;
    margin-right: auto !important;
}

.header .navbar .navbar-nav .nav-item a.active {
    color: $white;
}

.sticky .navbar .navbar-nav .nav-item a.active {
    color: $theme-color;
}

.sticky .navbar .navbar-nav .nav-item a {
    color: $black;
}

.header .navbar .navbar-nav .nav-item .sub-menu a.active {
    color: $white;
}

.sticky .navbar .navbar-nav .nav-item .sub-menu a.active {
    color: $white;
}

.sticky .navbar .mobile-menu-btn .toggler-icon {
    background: #333;
}

/*===== NAVBAR =====*/
.navbar-area {
    width: 100%;
    z-index: 99;
    transition: all 0.3s ease-out 0s;
    padding: 0;
}

.navbar-area.sticky {
    position: fixed;
    z-index: 99;
    background: $theme-color;
    box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease-out 0s;
    background: #fff;
    padding: 0px 0;
}

.navbar {
    padding: 0;
    position: relative;
    transition: all 0.3s ease-out 0s;
}

.navbar-brand {
    padding-left: 0;
    border-radius: 0;
}

.mobile-menu-btn {
    padding: 0px;
}

.mobile-menu-btn:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
}

.mobile-menu-btn .toggler-icon {
    width: 30px;
    height: 2px;
    background-color: #222;
    display: block;
    margin: 5px 0;
    position: relative;
    transition: all 0.3s ease-out 0s;
}

.mobile-menu-btn.active .toggler-icon:nth-of-type(1) {
    transform: rotate(45deg);
    top: 7px;
}

.mobile-menu-btn.active .toggler-icon:nth-of-type(2) {
    opacity: 0;
}

.mobile-menu-btn.active .toggler-icon:nth-of-type(3) {
    transform: rotate(135deg);
    top: -7px;
}

.navbar-nav .nav-item {
    z-index: 1;
    position: relative;
    margin-right: 40px;

    &:last-child {
        margin-right: 0 !important;
    }
}

.navbar-nav .nav-item:hover a {
    color: $white;
}

.sticky .navbar-nav .nav-item:hover a {
    color: $theme-color;
}


.navbar-nav .nav-item a {
    font-size: 15px;
    color: $white;
    transition: all 0.3s ease-out 0s;
    position: relative;
    padding: 35px 0;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    transition: all 0.3s ease-out 0s;
    position: relative;
    text-transform: capitalize;

    &::after {
        opacity: 0;
        visibility: hidden;
    }
}

.navbar-nav .nav-item:hover a:before {
    width: 100%;
}

.navbar-nav .nav-item a.active {
    color: $white;
}

.navbar-nav .nav-item a.dd-menu::after {
    content: "\ea58";
    font: normal normal normal 1em/1 "LineIcons";
    position: absolute;
    right: 17px;
    font-size: 10px;
    top: 50%;
    margin-left: 5px;
    margin-top: 0px;
    transition: all 0.3s ease-out 0s;
    height: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .navbar-nav .nav-item a.dd-menu::after {
        right: 13px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
    .navbar-nav .nav-item a.dd-menu::after {
        top: 18px;
        right: 0;
        transform: rotate(180deg);
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
    .navbar-nav .nav-item a.collapsed::after {
        transform: rotate(0deg);
    }
}

.navbar-nav .nav-item:hover>.sub-menu {
    top: 100%;
    opacity: 1;
    visibility: visible;
}

.navbar-nav .nav-item:hover>.sub-menu .sub-menu {
    left: 100%;
    top: 0;
}

.navbar-nav .nav-item .sub-menu {
    padding: 30px;
    min-width: 240px;
    background: $white;
    box-shadow: 0px 5px 20px #0000001a;
    position: absolute;
    top: 110% !important;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-out 0s;
    border-radius: 0 0 4px 4px;
    border-radius: 5px;
}

.navbar-nav .nav-item:hover {
    .sub-menu {
        top: 100% !important;
    }
}

.navbar-nav .nav-item .sub-menu .nav-item {
    width: 100%;
    margin-bottom: 15px;

    &:last-child {
        margin: 0;
    }
}

.navbar-nav .nav-item .sub-menu .nav-item a {
    padding: 0;
    color: #888;
    display: block;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
    z-index: 1;
    border-radius: 4px;
    overflow: hidden;
    transition: all 0.1s ease;

    &:hover {
        color: $theme-color;
    }
}

.navbar-nav .nav-item .sub-menu.left-menu {
    left: -100%;
}

.navbar-nav .nav-item .sub-menu.collapse:not(.show) {
    display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
    .navbar-nav .nav-item .sub-menu.collapse:not(.show) {
        display: none;
    }
}

.navbar-nav .nav-item .sub-menu>li {
    display: block;
    margin-left: 0;
}

.navbar-nav .nav-item .sub-menu>li:last-child {
    border: none;
}

.navbar-nav .nav-item .sub-menu>li.active>a {
    color: $theme-color !important;
}

.navbar-nav .nav-item .sub-menu>li>a {
    font-weight: 400;
    display: block;
    padding: 12px 15px;
    font-size: 14px;
    color: #222;
    font-weight: 400;
}

.navbar-nav .nav-item .sub-menu>li:first-child a {
    border: none;
}


.add-list-button {
    display: inline-block;
    margin-left: 10px;

    .btn {
        i {
            font-size: 14px;
            display: inline-block;
            margin-right: 5px;
        }
    }
}

/*======================================
     End Header CSS
  ========================================*/