.social-media img {
  height: 45px;
}

.navbar-brand h2 {
  color: white;
}

.header {
  background-color: #1B1927;
}

.faq {
  padding-top: 140px;
}

.post-thumbnails {
  padding: 20px;
  text-align: center;
}

@media (min-width:1200px) {
  .post-thumbnails img {
    max-width: 500px;
  }
}

.line-break {
  white-space: pre-wrap;
}
