.social-media img {
  height: 45px;
}

.navbar-brand h2 {
  color: white;
}

.header {
  background-color: #1B1927;
}

.faq {
  padding-top: 140px;
}

.blog-img a {
  width: 100%;
}

.single-blog {
  margin: 20px;
}

.blog-content {
  margin-top: 10px;
}

/* Container that sets the size and position */
.image-container {
  position: relative;
  width: 100%;
  /* or a fixed width like 370px if you prefer */
  height: 215px;
  /* or any desired fixed height, e.g., 250px */
  overflow: hidden;
  /* ensures blurred image doesn’t bleed out of container */
  border-radius: 8px;
  /* optional: matches your styling from RN */
}

/* Blurred background image */
.blurred-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* fill the container while preserving aspect ratio */
  filter: blur(10px);
  /* adjust blur as you wish */
  transform: scale(1.1);
  /* scale up slightly to hide edge artifacts from blur
     (especially if images are not large enough) */
}

/* Foreground image */
.foreground-image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* ensures the whole image is visible without cropping */
  z-index: 1;
  /* sits on top of the blurred background */
  border-radius: 8px;
  /* optional, to match styling */
}
